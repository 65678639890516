.adyen-checkout-ctp__otp-resend-code {
  font-size: 13px;
  font-weight: 400;
  color: #0075ff;
  margin-left: auto;
  cursor: pointer;
}

.adyen-checkout-ctp__otp-resend-code--disabled,
.adyen-checkout-ctp__otp-resend-code--confirmation {
  pointer-events: none;
  font-size: 13px;
  font-weight: 400;
  margin-left: auto;
  color: #687282;
  cursor: default;
}

.adyen-checkout-ctp__otp-resend-code--confirmation {
  display: flex;
  align-items: center;
}
.adyen-checkout-ctp__otp-resend-code--confirmation > img {
  margin-left: 4px;
}

.adyen-checkout-ctp__otp-resend-code-counter {
  font-size: 13px;
  font-weight: 400;
  margin-left: auto;
  cursor: default;
  color: black;
  display: inline-block;
  text-align: right;
}

.adyen-checkout__field.adyen-checkout__field--otp {
  margin-bottom: 24px;
}